import React, { useState, useEffect } from "react";
import Toggle from "react-toggle";
import firebase from "firebase";
import "react-toggle/style.css";

import "./Layout.scss";
import AddBookmarkDialog from "./add-bookmark-dialog/AddBookmarkDialog";

const Layout = ({ children, userDetail }) => {
  const [modal, setModal] = useState(false);
  const [checked, setChecked] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  var db = firebase.firestore();
  var auth = firebase.auth();

  console.log(auth.currentUser);

  console.log(auth.currentUser.uid);

  db.collection("bookmark")
    .where("uid", "==", auth.currentUser.uid)
    .get()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        console.log(doc.data());
      });
    });

  useEffect(() => {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }, []);

  const toggleModal = () => setModal(!modal);

  const toggleSwitch = () => {
    if (checked) {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
    } else {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
    }

    setChecked(!checked);
  };

  return (
    <div className="layout-container container-fluid d-flex flex-row p-0">
      {modal && (
        <AddBookmarkDialog
          modal={true}
          toggleModal={toggleModal}
          addnewTask={() => {}}
        />
      )}
      <header className="w-100 d-flex flex-row">
        <div className="search-container flex-grow-1 d-flex flex-row d-flex flex-row justify-content-between px-3">
          <input className="my-auto col-6" />
          <div className="d-flex flex-row align-items-center">
            <Toggle
              defaultChecked={checked}
              onChange={toggleSwitch}
              className="my-auto"
            />
            <img
              src={userDetail.photoURL}
              className="profile-pic my-auto ml-5"
            />
          </div>
        </div>
      </header>
      <div className="app-sidebar d-flex flex-column justify-content-between">
        <div className="">
          <div className="">
            <div className="title-text my-auto text-center d-flex align-items-center justify-content-center">
              Mark-It
            </div>
            <div className="px-3 pb-4">
              <button
                type="button"
                class="btn btn-primary btn-lg"
                onClick={toggleModal}
              >
                Add Link
              </button>
            </div>

            <div className="item selected cursor-pointer">
              <i className="fas fa-list-ul mr-2" />
              All Bookmarks
            </div>
            <div className="item ">
              <i className="fas fa-star mr-2" />
              Starred
            </div>
            <div className="tag-header py-3 d-flex justify-content-between align-items-center font-weight-semi-bold">
              TAGS
            </div>
          </div>
        </div>
        <div className="flex-fill mb-5"></div>
        <div className="m-3 btn bot-link text-primary" onClick={()=>{firebase.auth().signOut()}}>Logout</div>
        <a
          className="bmc-button mb-3 mx-3"
          target="_blank"
          href="https://www.buymeacoffee.com/maheswaranapk"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
            alt="Buy me a coffee"
          />
          <span className="buy-me-a-coffee">Buy me a coffee</span>
        </a>
      </div>
      <div className="main-container d-flex flex-column flex-fill">
        <main className="app-main flex-fill">{children}</main>
      </div>
    </div>
  );
};

export default Layout;
