import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import userActions from "../../store/actions/user.action";
import * as firebase from "firebase";
import localStorageUtil from "../../utils/localStorage.utils";
import "./LoginPage.scss";
import authConstant from "../../constants/auth.constant";

class LoginPage extends Component {
  state = {
    checked: localStorage.getItem("theme") === "dark" ? true : false,
    theme: localStorage.getItem("theme")
  };

  loginWithGoogle = () => {
    this.props.actions.authenticateUser();
  };

  componentDidMount() {
    document
      .getElementsByTagName("HTML")[0]
      .setAttribute("data-theme", localStorage.getItem("theme"));
  }

  toggleThemeChange = () => {
    const { checked } = this.state;
    if (checked === false) {
      localStorage.setItem("theme", "dark");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      this.setState({
        checked: true
      });
    } else {
      localStorage.setItem("theme", "light");
      document
        .getElementsByTagName("HTML")[0]
        .setAttribute("data-theme", localStorage.getItem("theme"));
      this.setState({
        checked: false
      });
    }
  };

  render() {
    return (
      <div className="login-page-container h-100 d-flex align-items-center justify-content-center">
        <section class="container-fluid">
          <div class="row">
            <div class="right px-5 d-flex flex-column justify-content-center align-items-center h-100 col-lg-6 text-center text-lg-left">
              <h1 class="display-4 mb-4">
                Mark and organize your fauvarite URLs
              </h1>
              <p class="lead text-secondary mb-4">
                Mark and organize your fauvarite URLs. Mark and organize your
                fauvarite URLs.
              </p>
              <div className="d-flex flex-row align-items-start w-100">
                <a
                  class="btn btn-primary text-white btn-block rounded-pill d-md-inline-block w-auto mr-md-4 mb-4 mb-md-0"
                  onClick={this.props.actions.authenticateUser}
                >
                  Connect with Google
                </a>
                <div
                  class="btn btn-link rounded-pill  mr-md-4 mb-4 mb-md-0"
                  onClick={() => {
                    firebase.auth().signInAnonymously();
                  }}
                >
                  Anomynous Login
                </div>
              </div>
            </div>
            <div class="left h-100 d-flex justify-content-center align-items-center col-0 col-xl-6">
              <img src="https://www.eipenroll.com/wp-content/uploads/2017/10/macbook-laptop-frame.png" />
            </div>
          </div>
        </section>
        {/* <input
          type="checkbox"
          checked={this.state.checked}
          defaultChecked={this.state.checked}
          onChange={() => this.toggleThemeChange()}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthLoading: state.userReducer.isAuthLoading,
  isAuthError: state.userReducer.isAuthError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      authorizeUser: userActions.authorizeUser,
      authenticateUser: userActions.authenticateUser
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
