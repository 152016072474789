import React from "react";
import { Route, Redirect } from "react-router-dom";

import Layout from "./layout/Layout";

const AuthenticatedRoute = ({
  component: Component,
  hideMenu,
  userDetail,
  ...rest
}) => {
  
  return (
    <Route
      {...rest}
      render={() =>
        true ? (
          <Layout userDetail={userDetail}>
            <Component />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default AuthenticatedRoute;
