import * as types from "../../constants/action-types.constant";

const initialState = {
  isAuthLoading: false,
  userDetail: null,
  isAuthError: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.AUTHENTICATE_REQUEST:
      return {
        ...state,
        userDetail: null,
        isAuthError: false
      };
    case types.AUTHORIZE_REQUEST:
      return {
        ...state,
        isAuthLoading: true,
        userDetail: null,
        isAuthError: false
      };
    case types.AUTH_REQUEST_SUCCESS:
      return {
        ...state,
        userDetail: action.payload,
        isAuthLoading: false
      };
    case types.AUTH_REQUEST_ERROR:
      return {
        ...state,
        isAuthLoading: false,
        isAuthError: true
      };
    case types.AUTH_LOGOUT:
      return {
        ...state,
        isAuthLoading: false,
        userDetail: null,
        isAuthError: false
      };
    default:
      return state;
  }
}
