import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, withRouter } from "react-router-dom";
import LoginPage from "./pages/login-page/LoginPage";
import ListPage from "./pages/list-page/ListPage";
import "./App.scss";
import AuthenticatedRoute from "./common/AuthenticatedRoute";
import userActions from "./store/actions/user.action";
import Spinner from "./component/spinner/Spinner";

import firebase from "firebase";
import localStorageUtils from "./utils/localStorage.utils";
import authConstant from "./constants/auth.constant";

const config = {
  apiKey: "AIzaSyCwZV2lc5oa4JCoCtHGOfqZGDFWDInc184",
  authDomain: "mark-it-84922.firebaseapp.com",
  databaseURL: "https://mark-it-84922.firebaseio.com",
  projectId: "mark-it-84922"
};

firebase.initializeApp(config);
firebase.firestore().enablePersistence({ synchronizeTabs: true });

const App = props => {
  const { isAuthLoading, userDetail } = props;

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log("======================================================");

        localStorageUtils.write(authConstant.LOGGED_IN, true);
        props.actions.authorizeUser({ user: user });
      } else {
        console.log("+++++++++++++++++++++++++++++++++++++++++++++++++++++++");

        localStorageUtils.write(authConstant.LOGGED_IN, false);
        props.actions.logOut();
      }
    });
  }, []);

  return (
    <div className="App position-relative">
      {localStorageUtils.read(authConstant.LOGGED_IN) && userDetail ? (
        <Switch>
          <AuthenticatedRoute
            exact
            path="/"
            component={ListPage}
            userDetail={userDetail}
          />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/" component={LoginPage} />
        </Switch>
      )}
      {isAuthLoading && (
        <div class="overlay">
          <Spinner />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  isAuthLoading: state.userReducer.isAuthLoading,
  userDetail: state.userReducer.userDetail,
  isAuthError: state.userReducer.isAuthError
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      authorizeUser: userActions.authorizeUser,
      logOut: userActions.logOut
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
