import { put } from "redux-saga/effects";
import * as firebase from "firebase";
import * as actionTypes from "../../constants/action-types.constant";

export function* authenticateUser() {
  try {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    const provider = new firebase.auth.GoogleAuthProvider();

    yield firebase.auth().signInWithPopup(provider);
  } catch (error) {
    yield put({ type: actionTypes.AUTH_REQUEST_ERROR, error });
  }
}

export function* authorizeUser(action) {

  if (action.payload && action.payload.user) {
    yield put({
      type: actionTypes.AUTH_REQUEST_SUCCESS,
      payload: action.payload.user
    });
  } else {
    yield put({ type: actionTypes.AUTH_REQUEST_ERROR });
  }
}
