import React, { Component } from "react";
import firebase from "firebase";
import DataCard from "../../data-card/DataCard";

class ListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      linkList: []
    };
  }

  componentDidMount() {
    const db = firebase.firestore();
    const auth = firebase.auth();

    db.collection("bookmark")
      .where("uid", "==", auth.currentUser.uid)
      .get()
      .then(querySnapshot => {
        var array = [];
        querySnapshot.forEach(function(doc) {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          array.push(doc.data());
        });

        this.setState({
          linkList: array
        });
      });
  }

  render() {
    const { linkList } = this.state;

    return (
      <div>
        <div className="row p-4">
          {linkList &&
            linkList.map((doc, index) => (
              <div className={"col-6 flex-fill p-0"}>
                <div className={(index % 2 === 0 ? "pl-3 pr-2" : "pr-3 pl-2")}>
                <DataCard
                  image={doc.image}
                  title={doc.title}
                  description={doc.description}
                  url={doc.url}
                />
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default ListPage;
