import * as actionTypes from "../../constants/action-types.constant";

const authenticateUser = () => ({
  type: actionTypes.AUTHENTICATE_REQUEST
});

const logOut = () => ({
  type: actionTypes.AUTH_LOGOUT
})

const authorizeUser = data => ({
  type: actionTypes.AUTHORIZE_REQUEST,
  payload: data
});

const userActions = {
    authenticateUser,
    authorizeUser,
    logOut
};

export default userActions;
