import React from "react";
import "./DataCard.scss";

const DataCard = ({ image, title, description, url }) => {
  return (
    <div
      className={"card rounded d-flex flex-row px-3 py-2 mb-3 cursor-pointer"}
      onClick={() => window.open(url, "_blank")}
    >
      {image && <img src={image} className="mr-3" />}

      <div>
        <div className="title mb-2">{title}</div>
        <div className="sub-title">{description}</div>
      </div>
    </div>
  );
};

export default DataCard;
