const write = (key, value) => {
  if (typeof Storage == "undefined") {
    alert(
      "Your browser doesn't support HTML5 LocalStorage which this site make use of. Some features may not be available. Consider upgrading your browser to the latest version"
    );
    return false;
  }
  value = JSON.stringify(value);
  window.localStorage.setItem(key, value);
  return true;
};

const read = key => {
  if (typeof Storage == "undefined") {
    return null;
  }

  const value = JSON.parse(localStorage.getItem(key));
  return value;
};

export default { write, read };
