import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import debounce from "lodash.debounce";
import urlMetadata from "url-metadata";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DataCard from "../../../data-card/DataCard";
import firebase from "firebase";
import "./AddBookmarkDialog.scss";

const AddBookmarkDialog = ({ modal, toggleModal, addnewTask }) => {
  const [url, setURL] = useState("");
  const [urlDetail, setUrlDetail] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const db = firebase.firestore();
  const auth = firebase.auth();

  const handleChange = e => {
    fetchURLDetail(e.target.value);
  };

  const fetchURLDetail = debounce(value => {
    setURL(value);
    setUrlDetail(undefined);
    setLoading(true);
    urlMetadata("https://cors-anywhere.herokuapp.com/" + value).then(
      function(metadata) {
        console.log(metadata);
        setUrlDetail({
          imageUrl: metadata.image,
          title: metadata.title,
          description: metadata.description,
          url: value,
          uid: auth.currentUser.uid
        });
 
        db.collection("bookmark").doc().set({
          imageUrl: metadata.image,
          title: metadata.title,
          description: metadata.description,
          url: value,
          uid: auth.currentUser.uid
        })
        setLoading(false);
      },
      function(error) {
        console.log(error);
        setUrlDetail(undefined);
        setLoading(false);
      }
    );
  }, 500);

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalBody>
        <div className="modal-container h-100 d-flex flex-column justify-content-between">
          <div>
            <div className="d-flex justify-content-between pb-3">
              <div className="fs-18 font-weight-bold modal-title">
                Add Bookmark
              </div>
              <i
                class="fas fa-times cursor-pointer p-2"
                onClick={toggleModal}
              />
            </div>
            <input
              className="url-input w-100 pb-3 mb-3"
              placeholder="Enter URL..."
              onChange={handleChange}
            />

            {loading && (
              <div className="p-2 mb-3">
                <SkeletonTheme
                  color={
                    localStorage.getItem("theme") === "light"
                      ? "#FFFFFF"
                      : "#162E4D"
                  }
                  highlightColor={localStorage.getItem("theme") === "light" ? "#f5f5f5" : "#2863ec"} 
                >
                  <div className="fs-16 font-weight-bold mb-1">
                    <Skeleton />
                  </div>
                  <span className="fs-12">
                    <Skeleton />
                  </span>
                </SkeletonTheme>
              </div>
            )}

            {!loading && urlDetail && (
              <DataCard
                image={urlDetail.image}
                title={urlDetail.title}
                description={urlDetail.description}
                url={urlDetail.url}
              />
            )}
          </div>
          <div className="d-flex flex-row-reverse pt-3 ">
            <button
              className="btn btn-primary"
              onClick={() => {
                addnewTask(urlDetail);
                toggleModal();
              }}
              disabled={loading || !urlDetail}
            >
              Add Bookmark
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddBookmarkDialog;
